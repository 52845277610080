import React from "react"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { Link, PageProps } from "gatsby"

const NotFound = (props: PageProps) => (
  <div className="min-h-full">
    <header className="hidden sm:block fixed z-20 left-[20vw] top-8 -mt-1.5">
      <h1 className="text-22px">404</h1>
    </header>
    <main className="mt-48 sm:mt-[20vw] mb-[145px] sm:mb-[129px]">
      <p className="mt-5 text-15px sm:text-3xl">
        Page not found. <Link className="-m-0.5 p-0.5 underline transition focus:outline-none focus-visible:ring focus-visible:ring-brand hocus:text-brand/50" to="/">Go home</Link>.
      </p>
    </main>
  </div>
)

export default withPrismicUnpublishedPreview(NotFound)
